import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Box from '../../components/box'
import { AuthProvider, AuthContext } from '../../context/auth'
import config from '../../utils/config'
import checkUtmParams from '../../utils/checkUtmParams'
import {
  AuthTitle,
  Button,
  Container,
  CopySubText,
  ErrorBanner,
  MobileAuthContainer,
} from '.'
import WorkspaceSetupSidebar from '../../components/workspaceSetupSidebar'
// import Select from '../../components/select'
import Input from '../../components/input'
import axios from 'axios'
import useOnEnterEventListener from '../../utils/hooks/useOnEnterEventListener'
import handleAuthNetworkError from '../../utils/handleAuthNetworkError'
import { departmentNames } from '../../utils/constants/signup'
import useHotjarScript from '../../utils/hooks/useHotjarScript'
import BasicSelect from '../../components/select/basicSelect'
import AuthSidebar from '../../components/authSidebar'
import { isMobileView } from '../../utils/isMobileView'
import MobileTopBar from '../../components/mobileTopBar'
// import Input from 'react-select/src/components/Input'

const CompleteProfileContainer = () => (
  <AuthProvider>
    <CompleteProfile />
  </AuthProvider>
)

const FormBox = styled.div`
  /* margin-top: 89px; */
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 769px) {
    margin-top: 0px;
  }
`

// const Header = styled.div`
//   font-family: ${props => props.theme.typography.fontFamilyGT};
//   font-weight: 700;
//   font-size: 20px;
//   line-height: 24px;
// `

// const Input = styled.input`
//   width: 260px;
//   height: 48px;
//   max-width: 90vw;
//   box-sizing: border-box;
//   background: ${props => props.theme.core.background.tertiary};
//   font-size: 16px;
//   line-height: 28px;
//   padding: ${props =>
//     `${props.theme.spacing(1.75)} ${props.theme.spacing(2.5)}`};
//   padding-right: 43px;
//   border: 2px solid transparent;
//   outline: none;
//   transition: all 100ms ease-in-out;
//   font-family: ${props => props.theme.typography.fontFamilyGTMono};
//   :focus {
//     background: ${props => props.theme.colors.white};
//     border: 2px solid ${props => props.theme.colors.black};
//   }
// `

const InputLabel = styled.label`
  font-family: ${props => props.theme.typography.fontFamilyGTMono};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #545454;
`

const CompleteProfile = () => {
  const { signupStepsMap, token, setLoading, navigateAuth } = useContext(
    AuthContext
  )
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [jobRole, setJobRole] = useState('')
  const [department, setDepartment] = useState('')
  const [error, setError] = useState(null)

  useEffect(() => {
    checkUtmParams()
  }, [])

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const result = await axios.post(
        `${config.backendUri}/auth/session`,
        {
          firstName,
          lastName,
          jobRole,
          department,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      navigateAuth(signupStepsMap.get(result.data.signupStep))
    } catch (error) {
      setLoading(false)
      setError(handleAuthNetworkError(error))
    }
  }

  useHotjarScript()

  useOnEnterEventListener(
    !(!firstName || !lastName || !jobRole || !department),
    () => {
      handleSubmit()
    }
  )

  if (isMobileView()) {
    return (
      <MobileAuthContainer>
        <SEO title="Complete profile" />
        <MobileTopBar>Profile</MobileTopBar>
        <div className="auth-mobile-body">
          <FormBox>
            <Box>
              <AuthTitle>More about you...</AuthTitle>
              <CopySubText
                style={
                  {
                    // marginTop: '16px',
                    // fontSize: '14px',
                    // lineHeight: '22px',
                  }
                }
              >
                You can get your profile in shape later, for now we just need
                some basic info to get you going.
              </CopySubText>
              <Box style={{ marginTop: '24px' }}>
                {/* <InputLabel>First Name</InputLabel> */}
                <Input
                  placeholder="First name"
                  style={{ width: '100%' }}
                  value={firstName}
                  onChange={event => setFirstName(event.target.value)}
                />
              </Box>
              <Box style={{ marginTop: '10px' }}>
                {/* <InputLabel>Last Name</InputLabel> */}
                <Input
                  placeholder="Last name"
                  style={{ width: '100%' }}
                  value={lastName}
                  onChange={event => setLastName(event.target.value)}
                />
              </Box>
              <Box style={{ marginTop: '10px' }}>
                {/* <InputLabel>Role</InputLabel> */}
                <Input
                  placeholder="Your role"
                  style={{ width: '100%' }}
                  value={jobRole}
                  onChange={event => setJobRole(event.target.value)}
                />
              </Box>
              <Box style={{ marginTop: '10px' }}>
                <BasicSelect
                  options={departmentNames.map(option => ({
                    label: option,
                    value: option.toLowerCase().split(' ').join('_'),
                  }))}
                  placeholder="Department"
                  value={department}
                  onChange={event => {
                    setDepartment(event.target.value)
                  }}
                />
              </Box>
              <Button
                disabled={!firstName || !lastName || !jobRole || !department}
                onClick={() => handleSubmit()}
              >
                That's Me
              </Button>
              {error && (
                <Box style={{ marginTop: '24px' }}>
                  <ErrorBanner>{error}</ErrorBanner>
                </Box>
              )}
            </Box>
          </FormBox>
        </div>
      </MobileAuthContainer>
    )
  }

  return (
    <Layout style={{ height: '100vh' }}>
      <SEO title="Complete profile" />
      {/* <WorkspaceSetupSidebar step={2} remainingTime="3 min" /> */}
      <Container>
        <AuthSidebar currentStepIndex={1} />
        <FormBox>
          <Box style={{ width: '400px' }}>
            <AuthTitle>More about you...</AuthTitle>
            <CopySubText
              style={{
                marginTop: '16px',
                // fontSize: '14px',
                // lineHeight: '22px',
              }}
            >
              You can get your profile in shape later, for now we just need some
              basic info to get you going.
            </CopySubText>
            <Box style={{ marginTop: '24px' }}>
              {/* <InputLabel>First Name</InputLabel> */}
              <Input
                placeholder="First name"
                style={{ width: '100%' }}
                value={firstName}
                onChange={event => setFirstName(event.target.value)}
              />
            </Box>
            <Box style={{ marginTop: '10px' }}>
              {/* <InputLabel>Last Name</InputLabel> */}
              <Input
                placeholder="Last name"
                style={{ width: '100%' }}
                value={lastName}
                onChange={event => setLastName(event.target.value)}
              />
            </Box>
            <Box style={{ marginTop: '10px' }}>
              {/* <InputLabel>Role</InputLabel> */}
              <Input
                placeholder="Your role"
                style={{ width: '100%' }}
                value={jobRole}
                onChange={event => setJobRole(event.target.value)}
              />
            </Box>
            <Box style={{ marginTop: '10px' }}>
              <BasicSelect
                options={departmentNames.map(option => ({
                  label: option,
                  value: option.toLowerCase().split(' ').join('_'),
                }))}
                placeholder="Department"
                value={department}
                onChange={event => {
                  setDepartment(event.target.value)
                }}
              />
              {/* <InputLabel>Department</InputLabel> */}
              {/* <Select
                style={{
                  marginTop: '10px',
                  backgroundColor: 'white',
                }}
                options={departmentNames.map(option => ({
                  label: option,
                  value: option.toLowerCase().split(' ').join('_'),
                }))}
                placeholder="Department"
                value={department}
                onChange={value => {
                  setDepartment(value)
                }}
              /> */}
            </Box>
            <Box
              style={{
                marginTop: '24px',
              }}
            />
            <Button
              // style={{ width: '100%', height: '48px', marginTop: '16px' }}
              disabled={!firstName || !lastName || !jobRole || !department}
              onClick={() => handleSubmit()}
            >
              That's Me
            </Button>
            {error && (
              <Box style={{ marginTop: '16px' }}>
                <ErrorBanner>{error}</ErrorBanner>
              </Box>
            )}
          </Box>
        </FormBox>
      </Container>
    </Layout>
  )
}

export default CompleteProfileContainer
